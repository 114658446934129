$letter-spacing: 8px;
$letter-spacing-sm: 3px;
$letter-spacing-none: 0px;

$mobile-breakpoint: 767px;

$position-labels: ("top", "left", "right", "bottom");

@each $position-label in $position-labels {
  .bordered-#{$position-label} {
    border-#{$position-label}: 1px solid #000 !important;
  }
}

$mainGutter: 60px;
$mainGutter-sm: $mainGutter/2;
$mainGutter-xs: $mainGutter-sm/2;

body {
  width: 100%;
  overflow-x: hidden;
}

/* HEADER */

#header-top {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: $mainGutter;
  transition: all ease-in-out 0.5s;
  z-index: 100;

  @media (max-width: $mobile-breakpoint) {
    padding: 15px;
  }

  &.sticky {
    background-color: rgba(255,255,255,0.85);
    padding-top: $mainGutter-xs;
    padding-bottom: $mainGutter-xs;
    transition: all ease-in-out 0.5s;
  }

  #logo img {
    max-width: 250px;
  }

  /* MENU */

  #menu-button {
    width: 22px;
    height: 22px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 1000;
  }

  #menu-button span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  #menu-button span:nth-child(1) {
    top: 0px;
  }

  #menu-button span:nth-child(2) {
    top: 8px;
  }

  #menu-button span:nth-child(3) {
    top: 16px;
  }

  #menu-button.open span:nth-child(1) {
    top: 8px;
    transform: rotate(135deg);
    background: #FFF;
  }

  #menu-button.open span:nth-child(2) {
    opacity: 0;
    left: -$mainGutter;
  }

  #menu-button.open span:nth-child(3) {
    top: 8px;
    transform: rotate(-135deg);
    background: #FFF;
  }
}

.container {
  @media (max-width: $mobile-breakpoint) {
    padding-right: 30px;
    padding-left: 30px;
  }

  .row.bordered-bottom {
    margin: 0px;
  }
}

$menuContainerWidth: 280px;

/* MENU CONTAINER */
#menu-container {
  position: fixed;
  width: $menuContainerWidth;
  top: 0px;
  bottom: 0px;
  right: -$menuContainerWidth;
  background-color: #000;
  color: #FFF;
  transition: all ease-in-out 0.5s;
  z-index: 999;

  &.open {
    right: 0px !important;
  }

  ul {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px;
    padding: $mainGutter;

    li {
      list-style-type: none;
      border-bottom: 1px solid #FFF;

      a {
        color: #FFF;
        font-size: 1.3em;

        &:hover {
          text-decoration: none;
        }
      }

    }

  }
}

.main-menu-label {
  position: fixed;
  left: -50vh;
  top: 50%;
  transform: rotate(-90deg);
  transform-origin: top center;
  width: 100vh;
  text-align: center;
  display: none;

  @media (max-width: $mobile-breakpoint) {
    display: none;
  }

}

/* PAGE */

.full-screen {
  height: 100vh;

  @media (max-width: $mobile-breakpoint) {
    height: auto;
    margin-bottom: 30vh;
  }

  &.home {
    background-color: #000;
    overflow: hidden;

    .container {
      height: 100vh;
    }

    @media (max-width: $mobile-breakpoint) {
      height: 100vh;
    }
  }

  .vertical-centered {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      position: static;
      top: 0px;
      transform: none;
    }
  }

}

body a {
  color: #FF00BF;
}

.headline,
.scrolldown {

  text-align: center;
  font-size: 4em;
  line-height: 1em;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.scrolldown {
  bottom: 20px;
  top: auto;
  transform: none;
}

.chevron::before {
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}


section {

  line-height: 0px;

  &.first-section {

    @media (max-width: $mobile-breakpoint) {
      margin-top: 100px;

      &.home {
        margin-top: 0px;
      }
    }

  }

  .row {
    line-height: 1em;
  }

  .height-auto {
    margin-top: 12rem;

    @media (max-width: $mobile-breakpoint) {
      margin-top: 100px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ltc-bodoni-175, "Bauer Bodoni Std 2", serif;
    font-style: normal;
    font-weight: 700;
  }

  h1 {
    position: relative;
    font-size: 6em;
    letter-spacing: $letter-spacing;
    line-height: 0.9em;
    
    @media (max-width: $mobile-breakpoint) {
      font-size: 2em;
      letter-spacing: $letter-spacing-sm;
    }
  }

  h2 {
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 1rem;
    }
  }

  .half-height {
    height: 50vh;

    @media (max-width: $mobile-breakpoint) {
      height: auto;
    }

    iframe {
      height: 50vh;
      line-height: 0px;
      font-size: 0px;
      filter: grayscale(100%);
    }
  }

  .overline {

    &::after {
      content: "";
      position: absolute;
      bottom: 40%;
      left: 30px;
      width: 0px;
      border-top: 1px solid;
      transition: all ease-in-out 1s;

      @media (max-width: $mobile-breakpoint) {
        left: 20px;
      }

    }

    &.overline-visible::after {
      width: calc(100% - 30px) !important;
      transition: all ease-in-out 0.5s;

      @media (max-width: $mobile-breakpoint) {
        width: calc(100% - 10px) !important;;
      }

    }

    &.overline-right::after {
      left: 0px;
      right: 50px;
    }

  }

  .section-title {
    position: relative;
    top: -6em;

    @media (max-width: $mobile-breakpoint) {
      top: -4.1em;
    }
  }

  .page-payoff {
    text-align: right;
  }

  h3.page-payoff {
    font-size: 3.5em;
    letter-spacing: $letter-spacing;

    @media (max-width: 768px) {
      font-size: 2.5em;
    }

    @media (max-width: $mobile-breakpoint) {
      font-size: 1.2em;
      letter-spacing: $letter-spacing-sm;
    }
  }

  h4.page-payoff {
    font-size: 1.6em;
    letter-spacing: $letter-spacing-sm;
  }

  .page-text {
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5em;
  }

}

section .row.half-height .overline::after {
  bottom: 50%;
}

.cookie-acceptance {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 30px;
  z-index: 1000;
  background: #FFF;
  text-align: center;
}

.slide-wrapper {
  display: none;
}

.scene-nav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: inline-block;
}

.scene-nav--next {
  right: 2%;
}

.scene-nav--prev {
  left: 2%;
}